<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="名称：">
                <el-input v-model="searchName" placeholder="请输入名称/路由"></el-input>
            </el-form-item>
            <el-form-item label="路由：">
                <el-input v-model="searchUri" placeholder="请输入名称/路由"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search(1)">搜索</el-button></el-button> 
            </el-form-item>
        </el-form>
    </div>
    <div class="btn-box">
        <el-button type="primary" size="mini" @click="add">添加菜单</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="menuList"
      border>
      <el-table-column
          fixed
          prop="id"
          label="编号"
          width="60"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="菜单名称"
          width="200"
      >
      </el-table-column>
      <el-table-column
          prop="uri"
          label="路由"
      >
      </el-table-column>
      <el-table-column
          prop="weight"
          label="权重"
          width="60"
      >
      </el-table-column>
      <el-table-column
          label="状态"
          width="60"
      >
        <template slot-scope="scope">
          {{scope.row.status | status}}
        </template>
</el-table-column>
<el-table-column label="操作" width="300">
    <template slot-scope="scope">
    <el-button 
    size="mini" 
    :type="scope.row.status==1?'danger':'primary'"
    @click="changeMenuStatus(scope.row.id,scope.row.status)">{{scope.row.status==1?'隐藏':'显示'}}</el-button>
    <el-button 
      size="mini" 
      type="primary" 
      @click="dialogWin(false,scope.row.id)">添加下级菜单</el-button>
    <el-button 
      size="mini" 
      type="primary" 
      @click="dialogWin(true,scope.row.id,scope.row.name,scope.row.uri,scope.row.pid,scope.row.weight)">修改</el-button>
  </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 添加/修改弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form label-width="80px">
        <el-form-item label="菜单名称">
            <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="菜单URL">
            <el-input v-model="uri"></el-input>
        </el-form-item>
        <el-form-item label="上级">
            <el-select v-model="update?pid:menu_id" @change="change" filterable placeholder="请选择">
                <el-option v-for="item in AllMenu" :key="item.id" :label="item.name" :value="item.id">
                    <span v-if="item.id == 1">
                        {{item.name}}
                    </span>
                    <span v-else-if="children.indexOf(item.id) != -1">
                        | {{item.name}}
                    </span>
                    <span v-else-if="children_sub.indexOf(item.id) != -1">
                        |-- {{item.name}}
                    </span>
                    <span v-else>
                        |----- {{item.name}}
                    </span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="权重">
            <el-input v-model="weight" type="number"></el-input>
        </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog=false">取 消</el-button>
        <el-button type="primary" @click="postMenu">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'menuList',
        data() {
            return {
                menuList: [],
                model: '',

                page: 1,
                pageSize: 10,
                total: 0,

                loading: false,

                dialog: false,
                update: false,
                name: '',
                uri: '',
                pid: '',
                weight: '',
                menu_id: '',

                searchName: '',
                searchUri: '',

                AllMenu: [{
                    name: '小熊后台',
                    uri: 'xmx_admin_menu',
                    id: '1'
                }],
                children: ['1'],
                children_sub: []
            }
        },
        filters: {
            status(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = "显示"
                        break;
                    default:
                        name = "隐藏"
                        break;
                }
                return name
            }
        },
        mounted() {
            this.getMenulist()
            this.getAllMenu()
        },
        methods: {
            // 获取菜单
            getMenulist(page, size) {
                this.loading = true
                api.getMenulist({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pageSize,
                    name: this.searchName,
                    uri: this.searchUri
                }, res => {
                    this.menuList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            // 页面切换
            sizeChange(val) {
                this.pageSize = val
                this.getMenulist()
            },
            currentChange(val) {
                this.page = val
                this.getMenulist()
            },
            // 修改菜单状态
            changeMenuStatus(id, status) {
                api.changeMenuStatus({
                    menu_id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: "success",
                        message: res.msg
                    })
                    this.getMenulist()
                    this.getAllMenu()
                })
            },
            // 添加/修改弹窗
            dialogWin(update, menu_id, name, uri, pid, weight) {

                this.update = update
                this.name = name
                this.uri = uri
                this.pid = pid
                this.weight = weight
                this.menu_id = menu_id
                this.dialog = true
            },
            // 添加/修改菜单
            postMenu() {
                if (this.update) {
                    api.updateMenu({
                        name: this.name,
                        uri: this.uri,
                        pid: this.pid,
                        weight: this.weight,
                        menu_id: this.menu_id,
                    }, res => {
                        this.$message({
                            type: "success",
                            message: res.msg
                        })
                        this.getMenulist()
                        this.getAllMenu()
                        this.dialog = false
                    })
                    return
                }
                api.addMenu({
                    name: this.name,
                    uri: this.uri,
                    pid: this.menu_id,
                    weight: this.weight,
                }, res => {
                    this.$message({
                        type: "success",
                        message: res.msg
                    })
                    this.getMenulist()
                    this.getAllMenu()
                    this.dialog = false
                })

            },
            search(page) {
                this.getMenulist(page)
            },
            getAllMenu() {
                this.AllMenu = []
                api.getAllMenu(null, res => {
                    res.data.some(item => {
                        if (item.children != undefined) {
                            this.children.push(item.id)
                            item.children.some(sub => {
                                if (sub.children != undefined) {
                                    this.children_sub.push(sub.id)
                                }
                            })
                        }
                    })
                    this.AllMenu = [...this.AllMenu, ...this.steamroller(res.data)]
                })
            },
            steamroller(arr) {
                var newArr = [];
                arr.forEach(element => {
                    newArr.push(element)
                    if (element.children) {
                        element.f
                        newArr.push.apply(newArr, this.steamroller(element.children))
                        delete element.children
                    } else {

                    }
                });
                return newArr
            },
            add() {
                this.update = ''
                this.name = ''
                this.uri = ''
                this.pid = ''
                this.weight = ''
                this.menu_id = ''
                this.dialog = true
            },
            change(v) {
                if (this.update) {
                    this.pid = v
                }
            }
        },
    }
</script>
<style>
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>